import * as React from 'react';

import './notFound.scss';

export const NotFoundPage = () => {
  return (
    <main>
      <div className="not-found">
        <div className="background">
          <div className="ground"></div>
        </div>
        <div className="container">
          <div className="left-section">
            <div className="inner-content">
              <h1 className="heading">
                <div className="logo" aria-label="ESP" />
              </h1>
              <p className="subheading">Sorry, the requested dashboard could not be found.</p>
            </div>
          </div>
          <div className="right-section">
            <img src="/images/notFound.svg" alt="not found" />
          </div>
        </div>
      </div>
    </main>
  );
};
